import React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import Moment from "react-moment";
import { AmpXImage } from "../../../customs/ampImage";
import defaultImg from "../../../../images/opsio_contact.webp";

function Blog({ data, slugPrefix, isAMP, blog }) {
  const { NoImage } = useStaticQuery(
    graphql`
      query {
        NoImage: file(relativePath: { eq: "no-image.png" }) {
          childImageSharp {
            fluid(maxWidth: 750, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  return (
    <div className="relatedBlogs">
      <div className="title">{blog.relatedBlogs}</div>
      <div className="blogsWrapper">
        {data.map((item, index) => {
          return (
            <Link to={`/${slugPrefix}${item.node.slug}/`} key={index}>
              <div className="blogCard" key={index}>
                {item.node?.blogimg &&
                item.node.blogimg?.[0]?.ext === ".svg" ? (
                  <AmpXImage
                    isAMP={isAMP}
                    src={data?.blogimg[0]?.url}
                    alt={data?.title}
                  />
                ) : (
                  <img
                    src={defaultImg}
                    alt={data?.title || "default"}
                    className="defaultImage"
                  />
                )}
                <div className="blogTitle">{item.node.title}</div>
                <div className="blogSubTitle">{item.node.heading1}</div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item?.node?.preview?.data?.preview,
                  }}
                  className="blogDescription"
                ></div>
                <div className="blogDate">
                  <div className="date">
                    <Moment format="MMMM Do YYYY">{item.node.date}</Moment>
                  </div>
                  <div className="divider" />
                  <div className="time">{item.node.time}</div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default Blog;
